import {
  Link,
  AppProvider,
  TextContainer,
  Heading,
  TextStyle,
  Subheading,
  Page
} from '@shopify/polaris'
// import '@shopify/polaris/build/esm/styles.css'
import translations from '@shopify/polaris/locales/en.json'

function App() {
  const pageMarkup = (
    <Page>
      <TextContainer>
        <div style={{ textAlign: 'center', marginTop: '2rem' }}>
          <Heading>WeChat Mini-program Connector Privacy Policy</Heading>
        </div>
        <p>
          WeChat Mini-program Connector "the App” provides a set of REST API
          endpoints for connecting Shopify stores with WeChat Mini-programs "the
          Service" to merchants who use Shopify to power their stores. This
          Privacy Policy describes how personal information is collected, used,
          and shared when you install or use the App in connection with your
          Shopify-supported store.
        </p>
        <Subheading>Personal Information the App Collects</Subheading>
        <p>
          When you install the App, we are automatically able to access certain
          types of information from your Shopify account:
        </p>
        <ul>
          <li>Shop name</li>
          <li>OAuth token</li>
        </ul>
        <p>
          Additionally, we collect the following types of personal information
          from you and/or your customers once you have installed the App and
          connected with your WeChat Mini-program account:
        </p>
        <ul>
          <li>WeChat Mini-program AppID</li>
          <li>WeChat Mini-program AppSecret</li>
        </ul>
        <p>
          Information about you and others who may access the App on behalf of
          your store, such as your name, address, email address, phone number,
          and billing information; Information about individuals who visit your
          store, such as their IP address, web browser details, time zone, and
          information about the cookies installed on the particular device.
        </p>
        <p>
          We collect personal information directly from the relevant individual,
          through your Shopify account, or using the following technologies:
          “Cookies” are data files that are placed on your device or computer
          and often include an anonymous unique identifier. For more information
          about cookies, and how to disable cookies, visit{' '}
          <Link external url="http://www.allaboutcookies.org">
            http://www.allaboutcookies.org
          </Link>
          . “Log files” track actions occurring on the Site, and collect data
          including your IP address, browser type, Internet service provider,
          referring/exit pages, and date/time stamps. “Web beacons,” “tags,” and
          “pixels” are electronic files used to record information about how you
          browse the Site.
        </p>
        <div>
          <TextStyle variation="strong">
            We DO NOT collect or store any data about customer, product or
            order, these data are ONLY stored in the Shopify store, the WeChat
            Mini-program Connector app only bridge the connection between your
            WeChat Mini-program and your Shopify store.
          </TextStyle>
        </div>
        <Subheading>How Do We Use Your Personal Information?</Subheading>
        <p>
          We use the personal information we collect from you and your customers
          in order to provide the Service and to operate the App. Additionally,
          we use this personal information to: Communicate with you; Optimize or
          improve the App; and Provide you with information or advertising
          relating to our products or services.
        </p>
        <Subheading>Sharing Your Personal Information</Subheading>
        <ul>
          <li>
            We may share or transfer your information in the course of any
            direct or indirect reorganization process including, but not limited
            to, mergers, acquisitions, divestitures, bankruptcies, and sales of
            all or a part of our assets.
          </li>
          <li>
            We may also share your Personal Information to comply with
            applicable laws and regulations, to respond to a subpoena, search
            warrant or other lawful request for information we receive, or to
            otherwise protect our rights.
          </li>
        </ul>
        <p>
          Your Rights If you are a European resident, you have the right to
          access personal information we hold about you and to ask that your
          personal information be corrected, updated, or deleted. If you would
          like to exercise this right, please contact us through the contact
          information below.
        </p>
        <p>
          Additionally, if you are a European resident we note that we are
          processing your information in order to fulfill contracts we might
          have with you (for example if you make an order through the Site), or
          otherwise to pursue our legitimate business interests listed above.
          Additionally, please note that your information will be transferred
          outside of Europe, including to Canada and the United States.
        </p>
        <p>
          Data Retention When you place an order through the Site, we will
          maintain your Order Information for our records unless and until you
          ask us to delete this information.
        </p>
        <p>
          Changes We may update this privacy policy from time to time in order
          to reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.
        </p>
        <p style={{ marginBottom: '2rem' }}>
          Contact Us For more information about our privacy practices, if you
          have questions, or if you would like to make a complaint, please
          contact us by e-mail at{' '}
          <a href="mailto:privacy@projectone.biz">privacy@projectone.biz</a>
        </p>
      </TextContainer>
    </Page>
  )
  return <AppProvider i18n={translations}>{pageMarkup}</AppProvider>
}

export default App
